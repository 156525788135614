.chatbot {
    display: flex;
    position: relative;
    flex-direction: column;
    height: 80vh;
    /* max-width: 600px; */
    margin: 0 auto;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
  }

  .chips-container{
    background-color: #f9f9f9;
  }
  
  .messages {
    flex: 1;
    padding: 0 1rem;
    overflow-y: auto;
    background-color: #f9f9f9;
  }
  
  .message {
    padding: 1rem;
    margin: 1rem 0;
    border-radius: 8px;
  }
  
  .message.user {
    background-color: #d1e7ff;
    align-self: flex-start;
  }
  
  .message.bot {
    background-color: #ffe6e6;
    align-self: flex-end;
    overflow-y: auto;
    overflow-x: auto;
  }
  
  .input-form {
    display: flex;
    padding: 10px;
    background-color: #fff;
    border-top: 1px solid #ddd;
  }
  
  .input-wrapper {
    display: flex;
    width: 100%;
  }
  
  .input-field {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  .send-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    
  }

  .send-button.loading {
    cursor: default;
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(51 65 85 / var(--tw-text-opacity));
  }

  .send-button.disabled {
    cursor: default;
    background-color: #F9F9F9;
    color: gray;

  }

  .feedback-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .feedback-button {
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .feedback-button:hover {
    color: #007bff;
  }
  
  .feedback-button.highlighted {
    color: #007bff;
  }
  
  .feedback-message {
    font-size: 0.9rem;
    color: #28a745;
    margin-left: 10px;
  }
  
  select {
    margin-left: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  @media screen and (max-width: 700px) {
    .chips-container{
      display: none;
    }
  }
