.react-resizable {
    position: relative;
    height: fit-content;
    background-color: white;
}

.handle {
    width: 5px;
    height: 5px;
    opacity: 0.4;
    position: absolute;
}

.handle:hover {
    opacity: 1;

}

.handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
}

.handle-se {
    bottom: 6px;
    right: 6px;
    cursor: se-resize;
    border: 2px solid black;
    border-left: 0px;
    border-top: 0px;
}

.handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
}

.handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
}
